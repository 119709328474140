.npc {
	position: relative;
	flex: none!important;
}

.npc-avatar {
	width: 3.1cm;
	height: 3.1cm;
	position: absolute;
	top: 0.65cm;
	left: calc(0.32cm + var(--bleed));
	background-size: cover;
}

.theme-western .npc-avatar {
	width: 3.1cm;
	height: 5cm;
	position: absolute;
	top: 0cm;
	left: calc(0cm + var(--bleed));
	background-size: cover;
}

.npc-content {
	background-image: url(./npc-bg.png);
	background-size: calc(var(--card-width) + var(--max-bleed)*2);
	background-repeat: no-repeat;
	background-position: calc(var(--bleed) - var(--max-bleed)) 0;
	height: calc(4.52cm + var(--bleed));
	position: relative;
}

.theme-western .npc-content {
	background-image: url(./npc-bg-western.png);
}

.npc-technocrate .npc-content {
	background-image: url(./npc-technocrate-bg.png);
}

.npc-scavenger .npc-content {
	background-image: url(./npc-scavenger-bg.png);
}

.npc-adorateur .npc-content {
	background-image: url(./npc-adorateur-bg.png);
}

.npc-name{
	position: absolute;
	font-family: var(--font-title);
	font-size: 0.3cm;
	color: white;
	text-shadow: 0px 0px 2px black;
	top: 0.2cm;
	left: calc(3.8cm + var(--bleed));
}

.theme-western .npc-name{
	color: black;
	text-shadow: 0px 0px 2px white;
	top: 0.25cm;
}


.npc-hp {
	position: absolute;
	top: 0.44cm;
	left: calc(2.59cm + var(--bleed));
	color: white;
	font-weight: black;
	font-size: 0.35cm;
	width: 0.5cm;
	text-align: center;
}

.theme-western .npc-hp {
	top: 0.41cm;
	left: calc(2.6cm + var(--bleed));
}

.npc-def {
	position: absolute;
	top: 1.06cm;
	left: calc(3.21cm + var(--bleed));
	color: white;
	font-weight: black;
	font-size: 0.35cm;
	width: 0.5cm;
	text-align: center;
}

.theme-western .npc-def {
	top: 1.03cm;
	left: calc(3.23cm + var(--bleed));
}

.npc-actions {
	position: absolute;
	top: 1.2cm;
	left: calc(3.9cm + var(--bleed));
	color: white;
	width: 4.55cm;
	display: flex;
	height: 2.2cm;
	flex-direction: column;
	justify-content: center;
}

.npc-behavior {
	position: absolute;
	top: 0.1cm;
	left: calc(1.5cm + var(--bleed));
	width: 0.8cm;
	height: 0.8cm;
	background-size: contain;
}

.theme-western .npc-behavior {
	top: 0.05cm;
	left: calc(0.05cm + var(--bleed));
}

.npc-hostile {
	background-image: url('./npc-hostile.png');
}

.npc-ally {
	background-image: url('./npc-ally.png');
}

.npc-special {
	font-weight: bold;
	border-top: solid 1px;
	margin-top: 0.1cm;
	padding-top: 0.1cm;
	position: relative;
}

.npc-special-icon {
	background-image: url('./npc-special.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 0.4cm;
	height: 0.4cm;
	margin-right: 0.1cm;
	float: left;
}

.npc-special p {
	display: inline;
}

.npc-deathrattle {
	position: absolute;
	bottom: calc(0.29cm + var(--bleed));
	left: calc(3.2cm + var(--bleed));
	color: white;
	width: 5.4cm;
	height: 0.6cm;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.theme-western .npc-deathrattle {
	bottom: calc(0.26cm + var(--bleed));
	left: calc(3.9cm + var(--bleed));
	color: black;
	text-shadow: 0px 0px 2px white;
}