.karma-verso-bg,
.karma-verso-content {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.karma-verso-content {
	display: flex;
	flex-direction: column;
}

.karma-verso-value {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.karma-verso-value > span {
	padding: 1cm;
	border-radius: 0.5cm;
	background-color: rgba(0, 0, 0, 0.5);
	text-align: center;
	font-family: var(--font-title);
	color: white;
	text-shadow: 0px 0px 0.3cm black !important;
	line-height: normal;
	font-size: 2cm;
}

.karma-verso-value-small > span {
	font-size: 0.5cm;
}

.karma-verso-title {
	font-size: 0.5cm;
	font-family: var(--font-instruction);
	line-height: 0.8cm;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.5);
	padding-top: calc(0cm + var(--bleed));
}

.karma-verso-levelup {
	line-height: 0.8cm;
	text-align: right;
	padding-right: calc(0.5cm + var(--bleed));
	height: 0.8cm;
	padding-bottom: calc(0cm + var(--bleed));
	background-color: rgba(0, 0, 0, 0.5);
}

.karma-verso-levelup p {
	display: inline;
}

.karma-verso-fatal {
	position: absolute;
	z-index: 1;
	bottom: calc(0.2cm + var(--bleed));
	left: calc(0.2cm + var(--bleed));
}

.karma-faction {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.karma-faction > span {
	background: none;
	text-align: left;
	font-family: var(--font-instruction);
	color: white;
	text-shadow: 0px 0px 0.3cm black !important;
	line-height: normal;
	font-size: 0.3cm;
	padding: 1cm 0.3cm 1cm 4cm;
}

.karma-faction-technocrate {
	background-image: url(../narrative/npc/npc-technocrate-bg.png);
}

.karma-faction-scavenger {
	background-image: url(../narrative/npc/npc-scavenger-bg.png);
}

.karma-faction-adorateur {
	background-image: url(../narrative/npc/npc-adorateur-bg.png);
}