.object.bag,
.object.bag-head,
.object.bag-body,
.object.bag-foot {
	background-image: url(object-bag-bg.jpg)
}

.theme-western .object.bag,
.object.bag-head,
.object.bag-body,
.object.bag-foot {
	background-image: url(object-bag-bg-western.png)
}

.object.head, .object.body, .object.foot {
	background-image: url(object-bg.jpg)
}

.theme-western .object.head, .object.body, .object.foot {
	background-image: url(object-bg-western.png)
}

.object-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-left: calc(1.5cm + var(--bleed));
	box-sizing: border-box;
	background-image: url(object-header.png);
	background-size: contain;
	height: calc(0.8cm + var(--bleed));
	color: white;
}

.object-footer {
	position: absolute;
	z-index: 10;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0,0,0, 0.5);
	padding: 0.2cm calc(0.3cm + var(--bleed)) calc(0.2cm + var(--bleed)) calc(0.3cm + var(--bleed));
}

.object-footer p {
	display: inline;
}

.object-name {
	font-family: var(--font-title);
	font-size: 0.4cm;
	padding-top: calc(0.15cm + var(--bleed));
}

.object-connector{
	width: 1.5cm;
	height: 1.5cm;
	position: absolute;
	background-position: left center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	right: calc(-0.75cm + var(--bleed));
}

.object.bag .object-connector,
.object.bag-head .object-connector,
.object.bag-body .object-connector,
.object.bag-foot .object-connector {
	top: calc(1.25cm + var(--bleed));
	left: calc(-0.75cm + var(--bleed));
	background-position: left center;
	background-image: url('object-connector-bag.png');
}

.theme-western .object.bag .object-connector,
.object.bag-head .object-connector,
.object.bag-body .object-connector,
.object.bag-foot .object-connector {
	top: calc(2cm + var(--bleed));
	background-image: url('object-connector-bag-western.png');
}

.object.head .object-connector {
	top: calc(1.25cm + var(--bleed));
	background-image: url('object-connector-head.png');
}

.theme-western .object.head .object-connector {
	top: calc(1.5cm + var(--bleed));
	background-image: url('object-connector-head-western.png');
}

.object.body .object-connector {
	top: calc(2cm + var(--bleed));
	background-image: url('object-connector-body.png');
}

.theme-western .object.body .object-connector {
	top: calc(2.5cm + var(--bleed));
	background-image: url('object-connector-body-western.png');
}

.object.foot .object-connector {
	top: calc(2.75cm + var(--bleed));
	background-image: url('object-connector-foot.png');
}

.theme-western .object.foot .object-connector {
	top: calc(3.5cm + var(--bleed));
	background-image: url('object-connector-foot-western.png');
}

.object-image {
	position: absolute;
	width: calc(var(--card-width) + var(--max-bleed)*2);
	height: calc(var(--card-height) + var(--max-bleed)*2);
	top: calc(var(--bleed) - var(--max-bleed));
	left: calc(var(--bleed) - var(--max-bleed));
}

.object-cost {
	position: absolute;
	right: calc(0.2cm + var(--bleed));
	top: calc(0.2cm + var(--bleed));
}

.object-effect {
	color: white;
	text-align: center;
	font-size: 0.35cm;
	text-shadow: 0 0 0.1cm black;
	top: 1cm;
	left: 0;
	padding: calc(0.5cm + var(--bleed));
	box-sizing: border-box;
	position: absolute;
	width: 100%;
}

.object-action {
	z-index: 20;
	bottom: calc(0.9cm + var(--bleed));
	left: calc(0cm + var(--bleed));
	position: absolute;
	display: flex;
	flex-direction: row;
	color: white;
	right: calc(0.3cm + var(--bleed));
	justify-content: flex-end;
	align-items: center;
}

.object-action-1 {
	bottom: calc(3cm + var(--bleed));
}

.object-action-2 {
	bottom: calc(5.1cm + var(--bleed));
}

.object-action.object-action-nocamp {
	bottom: calc(0.3cm + var(--bleed));
}

.object-action-1.object-action-nocamp {
	bottom: calc(2.4cm + var(--bleed));
}

.object-action-2.object-action-nocamp {
	bottom: calc(4.5cm + var(--bleed));
}

.object-gem {
	position: relative;
	height: 2cm; 
	width: 2cm;
	background-image: url(./gem.png);
	background-repeat: no-repeat;
	background-size: 2cm 2cm;
	line-height: 2cm;
	text-align: center;
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-position: center;
	align-self: end;
}

.object-heart {
	position: absolute;
	bottom: -0.15cm;
	left: 0.65cm;
	width: 0.7cm;
	height: 0.7cm;
}

.object-gem .icon {
	width: 0.8cm;
	height: 0.8cm;
}

.object-action-content {
	background: rgba(0, 0, 0, 0.4);
	margin-right: -1cm;
	display: flex;
	align-items: center;
	padding-right: 1.2cm;
	padding-left: 0.3cm;
	padding-top: 0.2cm;
	padding-bottom: 0.2cm;
	border-radius: 0.4cm;
	margin-left: 0.3cm;
}
