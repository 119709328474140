.narrative {
	background-image: url(narrative-bg.jpg);
}
.theme-western .narrative {
	background-image: url(narrative-bg-western.png);
}
.narrative .content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
}

.narrative .content>* {
	flex: 1 0 auto;
}

.narrative .content .first {
	padding-top: calc(0.7cm + var(--bleed));
}

.narrative .content .last {
	border-bottom: none;
}