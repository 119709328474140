.character-bg {
	/*width: 100%;
	height: 100%;    
	position: absolute;
	top: 0cm;
	left: 0cm;*/

	position: absolute;
	width: calc(var(--card-width) + var(--max-bleed)*2);
	height: calc(var(--card-height) + var(--max-bleed)*2);
	top: calc(var(--bleed) - var(--max-bleed));
	left: calc(var(--bleed) - var(--max-bleed));
}

.character-action-1 .gem {
	bottom: calc(0.5cm + var(--bleed));
	right: calc(0.3cm + var(--bleed));
}

.character-action-2 .gem {
	bottom: calc(3cm + var(--bleed));
	right: calc(0.3cm + var(--bleed));
}

.character-action-3 .gem {
	bottom: calc(0.5cm + var(--bleed));
	left: calc(0.85cm + var(--bleed));
	opacity: 1
}

.character-name {
	position: absolute;
	top: calc(1cm + var(--bleed));
	left: 0;
	width: 100%;
	text-align: center;
	font-size: 1cm;
	font-family: var(--font-title);
	color: white;
	text-shadow: 0px 0px 0.3cm black;
}


.character-connector, 
.character-connector-bag {
	width: 1.5cm;
	height: 1.5cm;
	position: absolute;
	background-position: left center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.character-connector-bag {
	top: calc(1.25cm + var(--bleed));
	right: calc(-0.75cm + var(--bleed));
	background-position: right center;
	background-image: url('character-connector-bag.png');
}

.theme-western .character-connector-bag {
	top: calc(2cm + var(--bleed));
	background-image: url('character-connector-bag-western.png');
}

.character.head .character-connector {
	top: calc(1.25cm + var(--bleed));
	left: calc(-0.75cm + var(--bleed));
	background-image: url('character-connector-head.png');
}

.theme-western .character.head .character-connector {
	top: calc(1.5cm + var(--bleed));
	background-image: url('character-connector-head-western.png');
}

.character.body .character-connector {
	top: calc(2cm + var(--bleed));
	left: calc(-0.75cm + var(--bleed));
	background-image: url('character-connector-body.png');
}

.theme-western .character.body .character-connector {
	top: calc(2.5cm + var(--bleed));
	background-image: url('character-connector-body-western.png');
}

.character.foot .character-connector {
	top: calc(2.75cm + var(--bleed));
	left: calc(-0.75cm + var(--bleed));
	background-image: url('character-connector-foot.png');
}

.theme-western .character.foot .character-connector {
	top: calc(3.5cm + var(--bleed));
	background-image: url('character-connector-foot-western.png');
}