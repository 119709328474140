.map-action {
	position: absolute;
	z-index: 10;
	height: 0.38cm;
	line-height: 0.38cm;
	color: white;
	border-radius: 0.38cm;
	font-size: 0.4cm;
	padding: 0 1mm;
	text-shadow: 0px 0px 2px black;
	font-weight: bold;
	width: 1.2cm;
	text-align: center;
	margin-left: -0.6cm;
	margin-top: -0.19cm;
}

.map-action .icon {
	filter: drop-shadow( 0 0 0.1cm rgba(0, 0, 0, 1));
}

.map-action-material .icon {
	width: 0.5cm;
	height: 0.5cm;
}

.map-action-card-event {
	background: #e9262a;
	color: white;
	height: 0.45cm;
	vertical-align: top;
	line-height: 0.38cm;
	border-radius: 0.38cm;
	font-size: 0.25cm;
	padding: 0.5mm 1mm;
	font-weight: bold;
}

.map-bg {
	width: 100%;
	height: 100%;    
	top: 0;
	left: 0;
	position: absolute;
	background: gray;
}

.map-mode-default .map-bg {
	width: calc(var(--card-width) + var(--max-bleed)*2);
	height: calc(var(--card-height) + var(--max-bleed)*2);
	top: calc(var(--bleed) - var(--max-bleed));
	left: calc(var(--bleed) - var(--max-bleed));
}

.map-mode-default .map-action {
	margin-left: -0.6cm;
	margin-top: -0.19cm;
}

.map svg {
	width: 100%;
	height: 100%;
	position: absolute;
}

.map line {
	stroke-width: 0.25cm;
	stroke-linecap: round;
}

.map .map-grid,
.map .map-zone {
	stroke:rgba(255, 255, 255, 1);
	stroke-width: 0.09cm;
	border: solid 1px black;
}

.map-wall {
	stroke:rgba(0, 0, 0, 1);
}

.map-door {
	stroke:rgba(50, 205, 50, 1);
}

.map-window {
	stroke:rgba(3, 169, 244, 1);
}

.map .map-obstacle {
	stroke:#e630f6;
	stroke-width: 0.18cm;
}

.map .map-danger {
	stroke:rgb(255, 0, 0);
	stroke-width: 0.09cm;
}

.map-mode-small svg,
.map-mode-small {
	position: relative;
	width: 1.2cm; 
	height: 1.2cm; 
	border-radius: 0.1cm;
}

.map-mode-small {
	border: solid 1px gray;
}

.map-mode-small .icon {
	width: 0.4cm;
	height: 0.4cm;
}

.map-mode-small .map-action {
	margin-top: 0;
	margin-left: 0;
	padding: 0;
	width: auto;
	height: auto;
}

.map-mode-small line,
.map-mode-small .map-obstacle,
.map .map-danger {
	stroke-width: 0.12cm;
}

.map-mode-small .map-bg {
	opacity: 0.8;
}