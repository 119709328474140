
.gem {
	position: absolute;
	height: 2cm; 
	width: 2cm;
	background-repeat: no-repeat;
	background-size: 2cm 2cm;
}

.gem > .icon {
	width: 0.8cm;
	height: 0.8cm;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -0.4cm;
	margin-top: -0.4cm;
}

.gem .helper-move {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -0.3cm;
	margin-top: -0.2cm;
	font-size: 0.35cm;
}

.gem .helper-move .icon {
	width: 0.5cm;
	height: 0.5cm;
}

.gem-heart {
	position: absolute;
	bottom: -0.15cm;
	left: 0.65cm;
	width: 0.7cm;
	height: 0.7cm;
}

.gem-noFight {
	position: absolute;
	top: -0.15cm;
	left: 0.65cm;
	width: 0.7cm;
	height: 0.7cm;
}

.gem-command {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	font-size: 0.5cm;
}

.gem-color-gray {
	background-image: url('gem-gray.png');
}

.gem-color-yellow {
	background-image: url('gem-yellow.png');
}

.gem-color-red {
	background-image: url('gem-red.png');
}

.gem-color-blue {
	background-image: url('gem-blue.png');
}

.gem-color-purple {
	background-image: url('gem-purple.png');
}

.gem-color-green {
	background-image: url('gem-green.png');
}

.theme-western .gem-color-yellow {
	background-image: url('gem-yellow-western.png');
}

.theme-western .gem-color-red {
	background-image: url('gem-red-western.png');
}

.theme-western .gem-color-blue {
	background-image: url('gem-blue-western.png');
}

.theme-western .gem-color-purple {
	background-image: url('gem-purple-western.png');
}

.theme-western .gem-color-green {
	background-image: url('gem-green-western.png');
}
