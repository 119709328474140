.card-anchor {
	position: absolute;
	z-index: 50;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.card-anchor-S {
	bottom: 0;
	right: calc(0.25cm + var(--bleed));
	width: 1.1cm;
	height: calc(0.31cm + var(--bleed));
	background-size: 100%;
	background-position: top;
}

.card-anchor-N {
	top: 0;
	right: calc(0.25cm + var(--bleed));
	width: 1.1cm;
	height: calc(0.31cm + var(--bleed));
	background-size: 100%;
	background-position: bottom;
}

.card-anchor-W {    
	left: 0;
	bottom: calc(0.25cm + var(--bleed));
	width: calc(0.31cm + var(--bleed));
	height: 1.1cm;
	background-size: auto 100%;
	background-position: right;
}

.card-anchor-E {
	right: 0;
	bottom: calc(0.25cm + var(--bleed));
	width: calc(0.31cm + var(--bleed));
	height: 1.1cm;
	background-size: auto 100%;
	background-position: left;
}

.not-first .card-anchor-N,
.not-last .card-anchor-S {
	height: 0.31cm;
}


.card-anchor-organic {
	background-image: url(./organic-NS.png);
}

.card-anchor-mechanic {
	background-image: url(./mechanic-NS.png);
}

.card-anchor-top {
	background-image: url(./top-NS.png);
}

.card-anchor-bottom {
	background-image: url(./bottom-NS.png);
}

.card-anchor-left {
	background-image: url(./left-EW.png);
	bottom: calc(0.75cm + var(--bleed));
}

.card-anchor-right {
	background-image: url(./right-EW.png);
}

.card-anchor-car {
	width: 1.1cm;
	height: 1.1cm;
}

.card-anchor-car-S {
	background-image: url(./car-S.png);
	height: calc(0.55cm + var(--bleed));
	left: calc(50% - 0.55cm);
}

.card-anchor-car-N {
	background-image: url(./car-N.png);
	height: calc(0.55cm + var(--bleed));
	left: calc(50% - 0.55cm);
}

.card-anchor-car-W {
	background-image: url(./car-W.png);
	width: calc(0.55cm + var(--bleed));
	top: calc(50% - 0.55cm);
}

.card-anchor-car-E {
	background-image: url(./car-E.png);
	width: calc(0.55cm + var(--bleed));
	top: calc(50% - 0.55cm);
}

.card-anchor-road {
	width: 1.1cm;
	height: calc(0.55cm + var(--bleed));
	background-image: url(./road.png);
	left: calc(67% - 0.55cm);
}

.not-first .card-anchor-car-N,
.not-last .card-anchor-car-S,
.not-first .card-anchor-road {
	height: 0.55cm;
}