@page { margin: 0 }
body { 
	margin: 0;
	font-family: "Roboto", sans-serif;
	line-height: 0.6cm;
	font-size: 10pt;
	background: #e0e0e0;
	--max-bleed: 0.5cm;
	--card-width: 8.9cm;
	--card-height: 8.9cm;
}

.theme-sf {
	--card-width: 8.9cm;
	--card-height: 6.35cm;
}

.theme-pp {
	--card-width: 6.35cm;
	--card-height: 8.9cm;
}

.theme-pl {
	--card-width: 8.9cm;
	--card-height: 6.35cm;
}

p, ol {
	margin: 0;
}

ul {
	margin: 0px;
	margin-left: 0.35cm;
	padding: 0px;
}

.space {
	padding: 20px;
}

.space-bottom {
	padding: 0px 20px 20px 20px;
}

.build.hide-missingTranslation .missing-translation {
	border: solid 2px red;
	color: red;
}

.hide-missingTranslation .card:not(:has(.missing-translation)) {
	display: none;
}

.tabs {
	display: flex;
	flex-wrap: wrap;
	border-bottom: solid 1px black;
}

.tab {
	border-radius: 5px 5px 0px 0px;
	border: solid 1px black;
	margin: 5px 0px -1px 5px;
	padding: 10px;
	cursor: pointer;
	background-color: white;
}

.tab.selected {
	border-bottom: solid 1px #e0e0e0;
	background-color: #e0e0e0
}

.tab:hover,
button:hover,
.layout-container .card-plus:hover {
	background-color: #e0e0e0;
}

/** DOCUMENT **/

.compact {
	font-size: 0.2cm;
}

.reorder .layout-container,
.build .layout-container,
.print .layout-container,
.rawprint .layout-container {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	justify-content: center;
}

/* needed to avoid pdf to break inside cards */
@media print {
	.build .layout-container {
		display: block;
	}
}

.rawprint .print-hide {
	display: none !important;
}

.printEmpty,
.printEmpty .icon-white,
.printEmpty .character-name,
.printEmpty .cache-author,
.printEmpty .karma-verso-value > span {
	color: transparent !important;
	text-shadow: none !important;
}

.printEmpty .helper,
.printEmpty .icon,
.printEmpty .map-action {
	opacity: 0 !important;
}

.reorder>ul>li {
	list-style: none;
	display: flex;
	margin-bottom: 5px;
}

.reorder img {
	width: 200px;
}

.tts.cards {
	justify-content: flex-start;
}

.print img,
.print .error,
.reorder .error {
	width: 200px;
	height: 200px;
	background: white;
	margin: 5px;
	display: inline-block;
}

.print .error,
.reorder .error {
	background: red;
	text-align: center;
	color: white;
	align-items: center;
}

.reorder .error {
	margin: 0px;
}

h1 {
	margin: 0;
	text-transform: uppercase;
	font-size: 0.3cm;
	text-align: center;
}

/** HELPER **/

.helper {
	white-space: nowrap;
	font-weight: bold;
}

.helper-event {
	display: inline-block;
	background: #e9262a;
	color: white;
	border-radius: 0.38cm;
	font-size: 0.25cm;
	padding: 0.2mm 1.5mm;
	font-weight: bold;
}

.helper-discard {
	padding: 0.5mm;
}

.helper-discard .icon {
	width: 0.3cm;
	height: 0.3cm;
}

.helper-marker,
.content .map-action-marker {
	border: solid 1px black;
	background: white;
	padding: 0.01cm 0.1cm;
	border-radius: 1cm;
	color: black;
	text-shadow: none;
}

.helper-if,
.helper-then,
.helper-else,
.helper-or {
	text-transform: uppercase;
	font-weight: bold;
	color: #80c342;
}

.helper-family {
	text-transform: uppercase;
	font-weight: bold;
}

.content .map-action-marker {
	width: 0.4cm;
	text-align: center;
	margin-left: -0.2cm;
}

.content .map-action-marker .icon {
	display: none;
}

/** ICONS **/
.icon {
	height: 0.4cm;
	width: 0.4cm;
	display: inline-block;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.compact .icon {
	width: 0.3cm;
	height: 0.3cm;
}

.layout-container .card-plus {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 100px;
	cursor: pointer;
}

.card-container {
	position: relative;
	page-break-inside: avoid;
}

.card-options {
	display: none;
	position: absolute;
	top: 0px;
	right: 0px;
}

.card-container:hover .card-options {
	display: block;
}

.icon-white {
	color: white;
	text-shadow: 0px 0px 2px rgba(0,0,0,1);
}

.icon-card {background-image: url('../icons/icon-card.png');}
.icon-def {background-image: url('../icons/icon-def.png');}
.icon-flip {background-image: url('../icons/icon-flip.png');}
.icon-burn {background-image: url('../icons/icon-burncard.png');}
.icon-discard {background-image: url('../icons/icon-discard.png');}
.icon-event {background-image: url('../icons/icon-event.png');}
.icon-material {background-image: url('../icons/icon-material.png');}
.icon-cdiscard {background-image: url('../icons/icon-cdiscard.png');}
.icon-token {margin:0;background-image: url('../icons/icon-token.png');}
.icon-atq {background-image: url('../icons/icon-attack.png');}
.icon-move {background-image: url('../icons/icon-move.png');}
.icon-moveN {background-image: url('../icons/icon-move.png');}
.icon-moveW {background-image: url('../icons/icon-move.png');transform: rotate(-90deg);}
.icon-moveS {background-image: url('../icons/icon-move.png');transform: rotate(180deg);}
.icon-moveE {background-image: url('../icons/icon-move.png');transform: rotate(90deg);}
.icon-range {background-image: url('../icons/icon-range.png');}
.icon-range-cac {background-image: url('../icons/icon-cac.png');}
.icon-body {background-image: url('../icons/icon-interact.png');}
.icon-head {background-image: url('../icons/icon-look.png');}
.icon-foot {background-image: url('../icons/icon-move.png');}
.icon-deathrattle {background-image: url('../icons/icon-deathrattle.png');}
.icon-interact {background-image: url('../icons/icon-interact.png');}
.icon-look {background-image: url('../icons/icon-look.png');}
.icon-hostile {background-image: url('../icons/icon-hostile.png');}
.icon-ally {background-image: url('../icons/icon-ally.png');}
.icon-load {background-image: url('../icons/icon-load.png');}
.icon-discuss {background-image: url('../icons/icon-discuss.png');}
.icon-move-external {background-image: url('../icons/icon-move-external.png');}
.icon-move-child {background-image: url('../icons/icon-move-child.png');}

.icon-start-selena {background-image: url('../icons/icon-start-selena.png');}
.icon-start-brick {background-image: url('../icons/icon-start-brick.png');}
.icon-start-maeve {background-image: url('../icons/icon-start-maeve.png');}
.icon-start-djamal {background-image: url('../icons/icon-start-djamal.png');}

.map .icon-start,
.map .icon-start-selena,
.map .icon-start-brick,
.map .icon-start-maeve,
.map .icon-start-djamal {width: 1cm; height: 1cm;}

.icon-timeline {background-image: url('../icons/icon-timeline.png');}
.icon-timemarker {background-image: url('../icons/icon-timemarker.png');}
.icon-puzzle {background-image: url('../icons/icon-puzzle.png');}
.icon-lock {background-image: url('../icons/icon-lock.png');}
.icon-warning {background-image: url('../icons/icon-warning.png');}
.icon-drive {background-image: url('../icons/icon-drive.png');}
.icon-setup {background-image: url('../icons/icon-wrench.png'); height: 0.5cm;	width: 0.5cm;}
.icon-insecte {background-image: url('../icons/icon-insecte.png');}
.icon-levelup {background-image: url('../icons/icon-levelup.png');}
.icon-nofight {background-image: url('../icons/icon-noFight.png');}
.icon-lineV {background-image: url('../icons/icon-lineV.png');}
.icon-lineG {background-image: url('../icons/icon-lineGreen.png');}
.icon-lineB {background-image: url('../icons/icon-lineBlue.png');}
.icon-lineR {background-image: url('../icons/icon-lineRed.png');}


.icon-white .icon-def {background-image: url('../icons/wicon-def.png');}
.icon-white .icon-card {background-image: url('../icons/wicon-card.png');}
.icon-white .icon-flip {background-image: url('../icons/wicon-flip.png');}
.icon-white .icon-burn {background-image: url('../icons/wicon-burncard.png');}
.icon-white .icon-discard {background-image: url('../icons/wicon-discard.png');}
.icon-white .icon-event {background-image: url('../icons/wicon-event.png');}
.icon-white .icon-card-event {background-image: url('../icons/wicon-load.png');}
.icon-white .icon-move {background-image: url('../icons/wicon-move.png');}
.icon-white .icon-moveN {background-image: url('../icons/wicon-move.png');}
.icon-white .icon-moveW {background-image: url('../icons/wicon-move.png');transform: rotate(-90deg);}
.icon-white .icon-moveS {background-image: url('../icons/wicon-move.png');transform: rotate(180deg);}
.icon-white .icon-moveE {background-image: url('../icons/wicon-move.png');transform: rotate(90deg);}
.icon-white .icon-atq {background-image: url('../icons/wicon-attack.png');}
.icon-white .icon-range {background-image: url('../icons/wicon-range.png');}
.icon-white .icon-range-cac {background-image: url('../icons/wicon-cac.png');}
.icon-white .icon-body {background-image: url('../icons/wicon-interact.png');}
.icon-white .icon-head {background-image: url('../icons/wicon-look.png');}
.icon-white .icon-foot {background-image: url('../icons/wicon-move.png');}
.icon-white .icon-deathrattle {background-image: url('../icons/wicon-deathrattle.png');}
.icon-white .icon-interact {background-image: url('../icons/wicon-interact.png');}
.icon-white .icon-look {background-image: url('../icons/wicon-look.png');}
.icon-white .icon-move-external {background-image: url('../icons/wicon-move-external.png');}
.icon-white .icon-load {background-image: url('../icons/wicon-load.png');}
.icon-white .icon-discuss {background-image: url('../icons/wicon-discuss.png');}
.icon-white .icon-discuss-and-look {background-image: url('../icons/wicon-discuss-and-look.png');}
.icon-white .icon-timeline {background-image: url('../icons/wicon-timeline.png');}
.icon-white .icon-puzzle {background-image: url('../icons/icon-puzzle.png');}
.icon-white .icon-lock {background-image: url('../icons/wicon-lock.png');}
.icon-white .icon-start {background-image: url('../icons/wicon-start.png');}
.icon-white .icon-dgt {background-image: url('../icons/icon-dgt.png');}
.icon-white .icon-warning {background-image: url('../icons/wicon-warning.png');}
.icon-white .icon-drive {background-image: url('../icons/wicon-drive.png');}
.icon-white .icon-setup {background-image: url('../icons/wicon-wrench.png'); height: 0.5cm;	width: 0.5cm;}
.icon-white .icon-move-child {background-image: url('../icons/wicon-move-child.png');}