.timeline {
	padding: 0cm calc(0.4cm + var(--bleed)) calc(0.3cm + var(--bleed)) calc(0.4cm + var(--bleed));
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	background-image: url('./timeline-bg.jpg');
	background-size: cover;
	background-position: center;
	position: relative;
}

.theme-western .timeline {
	background-image: url('./timeline-bg-western.png');
}

.timeline-edit .ant-card-body {
	background-size: cover;
	background-image: url('./timeline-bg.jpg');
	color: white;
}

.theme-western .timeline-edit .ant-card-body {
	background-image: url('./timeline-bg-western.png');
}

.timeline-edit .ant-form-item,
.timeline-edit .ant-form-item-label > label {
	color: white;
}