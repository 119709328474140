.tox {
	z-index: 300;
}

.mce-content-body {
	box-sizing: border-box;
	margin: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.88);
	font-size: 14px;
	line-height: 1.5714285714285714;
	list-style: none;
	font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	background-color: #ffffff;
	background-image: none;
	border-width: 1px;
	border-style: solid;
	border-color: #d9d9d9;
	border-radius: 6px;
	transition: all 0.2s;
}

.mce-content-body:focus {
	border-color: #1677ff;
	box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
	outline: 0;
}

.mce-content-body:hover {
	border-color: #4096ff;
}

.form-map svg {
	width: 6cm;
	height: 6cm;
	position: relative;
	z-index: 2;
}

.form-map img {
	position: absolute;
	width: 6cm;
	height: 6cm;
}

.form-map line {
	stroke-width: 0;
	stroke:rgba(205, 205, 205, 1);
	stroke-linecap: round;
}

.form-map circle {
	opacity: 0.3;
	cursor: pointer;
}

.form-map circle:hover {
	opacity: 0.7;
	fill: green;
}

.form-map-selected line {
	stroke: rgb(1, 197, 34);
}

.form-map-selected line {
	stroke-width: 0.2cm;
}