.card.cache {
	background-image: url('cache.jpg');
	background-size: 100% 100%;
	background-position: center center;
}

.theme-western .card.cache {
	background-image: url('cache-western.png');
}

.cache .content {
	color: white;
}

.cache-title {
	position: absolute;
	left: 0cm;
	right: 0cm;
	top: calc(1cm + var(--bleed));
	font-size: 0.5cm;
	text-transform: uppercase;
	text-align: center;
}


.cache-author {
	position: absolute;
	left: 0px;
	right: 0px;
	text-align: center;
	top: calc(1.7cm + var(--bleed));
	font-size: 0.4cm;
	font-style: italic;
}

.cache-duration {
	position: absolute;
	left: calc(0.5cm + var(--bleed));
	top: calc(3cm + var(--bleed));
	font-size: 0.5cm;
}

.cache-start {
	position: absolute;
	left: calc(0.5cm + var(--bleed));
	right: calc(0.5cm + var(--bleed));
	bottom: calc(0.5cm + var(--bleed));
	background:  rgba(0, 0, 0, 0.2);
	border: solid 1px rgba(100, 100, 100, 0.7);
	padding: 0.3cm 0.5cm;
	border-radius: 0.1cm;
}