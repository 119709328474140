.action {			
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.1cm calc(0.1cm + var(--bleed));
	position: relative;
}

.action {
	background-image: url('action-header.jpg'), url('action-footer.jpg'), url('../text/text-bg.jpg');
	background-size: 101% 0.1cm, 101% 0.1cm, cover;
	background-repeat: no-repeat;
	background-position: top, bottom, center;
}

.theme-western .action {
	background-image: url('action-header.jpg'), url('action-footer.jpg'), url('../text/text-bg-western.png');
}

.action-edit .ant-card-body {
	background-image: url('action-header.jpg'), url('action-footer.jpg'), url('../text/text-bg.jpg');
	background-size: 101% 0.1cm, 101% 0.1cm, cover;
	background-repeat: no-repeat;
	background-position: top, bottom, center;
	color: white;
}

.theme-western .action-edit .ant-card-body {
	background-image: url('action-header.jpg'), url('action-footer.jpg'), url('../text/text-bg-western.png');
}

.action-edit .ant-form-item,
.action-edit .ant-form-item-label > label {
	color: white;
}

.action.first {
	padding-top: calc(0.1cm + var(--bleed));
	background-size: 101% calc(0.1cm + var(--bleed)), 101% 0.1cm, cover;
}

.action.last {
	padding-bottom: calc(0.1cm + var(--bleed));
	background-size: 101% 0.1cm, 101% calc(0.1cm + var(--bleed)), cover;
}

.action.action-passive {
	background-image: url('../text/text-bg.jpg');
	background-size: cover;
	background-position: center;
}

.theme-western .action.action-passive {
	background-image: url('../text/text-bg-western.png');
}

.action h1 {
	text-align: left;
}

.action-grid,
.action-icon {
	margin-left: 0.2cm;
	margin-right: 0.2cm;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.action-grid .action-ref {
	font-size: 0.3cm;
	font-weight: bold;
	margin-top: 0.1cm;
}
