.event {
	background-image: url(event-bg.jpg);
	background-size:  calc(var(--card-width) + var(--max-bleed)*2) calc(var(--card-height) + var(--max-bleed)*2)!important;
	background-position: center;
}

.event .content {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	height: 100%;
}

.event-content {	
	flex: 1 0 auto;
	display: flex;
	align-items: center;

}

.event .timeline {
	padding: 0px calc(0.43cm + var(--bleed)) calc(0.43cm + var(--bleed)) calc(0.43cm + var(--bleed));
}

.event-story {
	margin: calc(0.5cm + var(--bleed)) calc(0.5cm + var(--bleed)) 0.5cm calc(0.5cm + var(--bleed));
	padding: 0.8cm;
	background: url(event-label.png) no-repeat center;
	background-size: 100% 100%;
	font-size: 0.3cm;
	font-family: var(--font-narrative);
}

.event-author {
	font-weight: bold;
	text-transform: capitalize;
}