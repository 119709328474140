.text {
	padding: 0.2cm calc(0.4cm + var(--bleed));
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	position: relative;
	text-shadow: 0px 0px 2px rgba(0,0,0,1);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.text {
	background-image: url('./text-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.theme-western .text {
	background-image: url('./text-bg-western.png');
}

.text-edit .ant-card-body {
	background-image: url('./text-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	color: white;
}

.theme-western .text-edit .ant-card-body {
	background-image: url('./text-bg-western.png');
}

.text-edit .ant-form-item,
.text-edit .ant-form-item-label > label {
	color: white;
}

.text-hasType {
	padding: 0.2cm calc(0.4cm + var(--bleed)) 0.2cm calc(0.2cm + var(--bleed));

	background-image: url('text-header.jpg'), url('text-footer.jpg'), url('../text/text-bg.jpg');
	background-size: 101% 0.1cm, 101% 0.1cm, cover;
	background-repeat: no-repeat;
	background-position: top, bottom, center;
}

.theme-western .text-hasType {
	background-image: url('text-header.jpg'), url('text-footer.jpg'), url('../text/text-bg-western.png');
}

.text-hasType.first {
	background-size: 101% calc(0.1cm + var(--bleed)), 101% 0.1cm, cover;
}

.text-hasType.last {
	background-size: 101% 0.1cm, 101% calc(0.1cm + var(--bleed)), cover;
}


.text-hasType.first.last {
	background-size: 101% calc(0.1cm + var(--bleed)), 101% calc(0.1cm + var(--bleed)), cover;
}

.text.first {
	padding-top: calc(0.2cm + var(--bleed));
}

.text.last {
	padding-bottom: calc(0.2cm + var(--bleed));
}

.text-anytime {
	background-image: url('./text-anytime.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 0.7cm;
	height: 0.7cm;
	margin-right: 0.2cm;
	flex-shrink: 0;
}