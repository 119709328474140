.bleed-0 {
	--bleed: 0cm;
	--print-width: 337px;
	--print-height: 337px;
}

.bleed-2 {
	--bleed: 0.2cm;
	--print-width: 352px;
	--print-height: 352px;
}

.bleed-3 {
	--bleed: 0.3cm;
	--print-width: 359px;
	--print-height: 359px;
}

.bleed-4 {
	--bleed: 0.4cm;
	--print-width: 367px;
	--print-height: 367px;
}

.bleed-5 {
	--bleed: 0.5cm;
	--print-width: 374px;
	--print-height: 374px;
}

.card {
	--font-narrative: 'Pangolin', cursive;
	--font-instruction: 'Roboto', sans-serif;
	--font-title: 'Permanent Marker', cursive;

	width: calc(var(--card-width) + var(--bleed) * 2);
	height: calc(var(--card-height) + var(--bleed) * 2);
	position: relative;
	box-sizing: border-box;
	background-size: cover;
	overflow: hidden;
	font-family: var(--font-instruction);
	font-size: 0.25cm;
	line-height: 0.41cm;
}

.theme-western .card {
	font-size: 0.3cm;
}

/****** start lang ******/

/** ZH **/
.card.ZH {
	--font-title: 'ZH Title';
	--font-narrative: 'ZH Narrative';
	--font-instruction: 'ZH Instruction';
}

.card.ZH .npc-name {
	font-size: 0.4cm;
}

.card.ZH .object-effect {
	font-weight: bold;
}

.card.ZH .object-action-content {
	font-size: 0.24cm;
}

.card.ZH .karma-verso-title {
	font-weight: 300;
}

.card.ZH {
	text-align: justify;
}

/** RU **/

.card.RU {
	line-height: 0.37cm;
}

.card.RU .object-name {
	font-size: 0.36cm;
}

.card.IT .object-name {
	font-size: 0.36cm;
}

/** PL **/
.card.PL {
	--font-title: 'PL Title';
}

/***** end lang ******/

.card-editor {
	height: auto;
	overflow-y: auto;
	padding: 20px;
	background: #e0e0e0;
	width: auto;
	max-width: 15cm;
	border: solid 1px gray;
	border-radius: 5px;
}

@media screen and (min-width: 15cm) {
  .card-editor {
		min-width: 15cm;
		max-height: 80vh;
	}
}

.build .card {
	box-shadow: 2px 2px 5px #000;
	border-radius: 0.3cm;
	margin: 0.05cm;
}

.rawprint .card {
	width: var(--print-width);
	height: var(--print-height);

	transform-origin: top left;
	transform: scale(3);
}

.rawprint .card-container {
	width: calc(var(--print-width) * 3);
	height: calc(var(--print-height) * 3);
}

.card-info {
	position: absolute;
	background: white;
	z-index: 10;
	top: calc(0.2cm + var(--bleed));
	left: calc(0.2cm + var(--bleed));
	height: 0.38cm;
	line-height: 0.38cm;
	border-radius: 0.38cm;
	font-size: 0.25cm;
	padding: 0 1mm;
	font-weight: bold;
	color: black;
}

.build .card-info-dev {
	background: red;
}

.card-info .icon,
.card-event .icon {
	height: 0.3cm;
	width: 0.3cm;  
}
.card-event {
	position: absolute;
	background: red;
	color: white;
	z-index: 10;
	top: 0.2cm;
	left: 1.5cm;
	height: 0.38cm;
	line-height: 0.38cm;
	border-radius: 0.38cm;
	font-size: 0.25cm;
	padding: 0 1mm;
	font-weight: bold;
}
.card-tag {
	position: absolute;
	background: white;
	z-index: 10;
	top: 0.2cm;
	right: 0.2cm;
	height: 0.38cm;
	line-height: 0.38cm;
	border-radius: 0.38cm;
	font-size: 0.25cm;
	padding: 0 1mm;
	font-weight: bold;
}

.card-version {
	position: absolute;
	color: #fff;
	right: calc(0.1cm + var(--bleed));
	z-index: 10;
	bottom: calc(0cm + var(--bleed));
	opacity: 0.75;
	text-shadow: 0 0 1px #000;
}

.card-editor-buttons button {
	margin: 5px;
	cursor: pointer;
	padding: 5px;
	word-spacing: 5px;
}

.card-editor .tabs {
	margin: 0px;
}

.card-editor .tab {
	padding: 5px 20px;
}